<template>
  <div class="full-container">
    <title-header :title="'平板锁设备管理'" :icon="false"/>

    <ul class="flex router-list">
      <li class="flex-column-center router-list-item">
        <router-link class="flex-column-center" to="/doTest">
          <img src="./../assets/images/lock-icon-test.png" class="list-item-icon" alt="">
          <p class="list-desp">平板锁生产测试</p>
        </router-link>
      </li>
      <li class="flex-column-center router-list-item">
        <router-link class="flex-column-center" to="/record">
          <img src="./../assets/images/lock-icon-record.png" class="list-item-icon" alt="">
          <p class="list-desp">平板锁测试记录</p>
        </router-link>
      </li>
      <li class="flex-column-center router-list-item">
        <router-link class="flex-column-center" to="/setting">
          <img src="./../assets/images/lock-icon-id.png" class="list-item-icon" alt="">
          <p class="list-desp">平板锁ID设置</p>
        </router-link>
      </li>
       <li class="flex-column-center router-list-item">
        <router-link class="flex-column-center" to="/setting-record">
          <img src="./../assets/images/lock-icon-record.png" class="list-item-icon" alt="">
          <p class="list-desp">平板锁ID设置记录</p>
        </router-link>
      </li>
      <li class="flex-column-center router-list-item">
        <router-link class="flex-column-center" to="/remove">
          <img src="./../assets/images/icon-remove-id.png" class="list-item-icon" alt="">
          <p class="list-desp">平板锁ID删除</p>
        </router-link>
      </li>
      <li class="flex-column-center router-list-item">
        <router-link class="flex-column-center" to="/bind-parts">
          <img src="./../assets/images/change-icon.png" class="list-item-icon" alt="">
          <p class="list-desp">锁体配件绑定</p>
        </router-link>
      </li>
    </ul>

    <div class="version">版本：{{ $version }}</div>

  </div>

</template>

<script>

import TitleHeader from "../components/TitleHeader";

export default {
  name: 'Home',
  components: {
    TitleHeader
  },
  data() {
    return {

    }
  },
  methods: {

  },
  mounted(){

  }
}
</script>
<style scoped lang="less">

.router-list-item {
  width: 50%;
  display: flex;
  margin: 0.65rem 0;
  .list-item-icon {
    width: 1rem;
  }
  .list-desp {
    font-size: 0.35rem;
    color: #666;
    margin-top: 0.3rem;
  }
}


</style>
